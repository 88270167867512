import { Tab } from "bootstrap/js/dist/tab.js";
import { Carousel } from "bootstrap/js/dist/carousel.js";

import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);
console.log(ScrollTrigger);


const faqElems = document.querySelectorAll(".section__faq_list li>div");
const helloVideo = document.querySelector('.hello__images');
const helloVideoPopup = document.querySelector('.hello_video_bg');
const helloVideoClose = document.querySelector(".hello_video_bg span");
const bodyDom = document.querySelector('body');

if (faqElems) {
    [...faqElems].forEach((faqi) => {
        faqi.addEventListener("click", (e) => {
            const faq = e.target;
            faq.classList.toggle("show");
        });
    });

}

let menuFlag = true;
let scrollHeightMax = 0;
const menuA = document.querySelector("header");
const cards = gsap.utils.toArray(".card.anim__scrolldown");
const height = (cards.length - 1) * 100 + "%";

// SCROLL TRIGGER DEFINITIONS

let pinScrollSection;
pinScrollSection = gsap
    .timeline()
    .fromTo(
        '.card[data-section="1"]',
        { opacity: 1 },
        { opacity: 0, ease: "expo.inOut" },
        "conect_1"
    )
    .fromTo(
        '.card[data-section="2"]',
        { opacity: 0 },
        { opacity: 1, ease: "expo.inOut" },
        "conect_1"
    )
    .fromTo(
        ".load__bar .loaded",
        { y: 0, backgroundColor: "#5D4CC6" },
        { y: "100%", backgroundColor: "#FFC329", ease: "expo.inOut" },
        "conect_1"
    )
    .fromTo(
        '.card[data-section="2"]',
        { opacity: 1 },
        { opacity: 0, ease: "expo.inOut" },
        "conect_2"
    )
    .fromTo(
        ".load__bar .loaded",
        { y: "100%", backgroundColor: "#FFC329" },
        { y: "240%", backgroundColor: "#F93665", ease: "expo.inOut" },
        "conect_2"
    )
    .fromTo(
        '.card[data-section="3"]',
        { opacity: 0 },
        { opacity: 1, ease: "expo.inOut" },
        "conect_2"
    );


let pinImpact;
pinImpact = gsap.timeline().fromTo(
    ".section__container_impact .text__claim span",
    { opacity: 0, x: -50, ease: "expo.out", stagger: 0.1 },
    {
        opacity: 1,
        x: 0,
        stagger: 0.1,
        ease: "expo.out",
    }
);

let pinImports;
pinImports = gsap.timeline().fromTo(
    ".section__container_exports .imports",
    { opacity: 0, x: -50, ease: "expo.out", stagger: 0.1 },
    {
        opacity: 1,
        x: 0,
        stagger: 0.1,
        ease: "expo.out",
    }
);

let pinExports;
pinExports = gsap.timeline().fromTo(
    ".section__container_exports .exports",
    { opacity: 0, x: -50, ease: "expo.out", stagger: 0.1 },
    {
        opacity: 1,
        x: 0,
        stagger: 0.1,
        ease: "expo.out",
    }
);

let pinForyou;
pinForyou = gsap.timeline().fromTo(
    ".section__container_foryou .title",
    { opacity: 0, x: -50, ease: "expo.out"},
    {
        opacity: 1,
        x: 0,
        ease: "expo.out",
    },'conect_export'
    ).fromTo(
        ".section__container_foryou .main",
        { opacity: 0, x: -50, ease: "expo.out"},
        {
            opacity: 1,
            x: 0,
            delay:0.2,
            ease: "expo.out",
        },'conect_export',
    );

// SECTION SCROLL DOWN
ScrollTrigger.create({
    animation: pinScrollSection,
    trigger: 'section[data-anim="scroll_down_sections"]',
    pin: true,
    start: "top top",
    end: "+=" + height,
    scrub: 0.5,
    snap: 0.5,
    markers: false,
    anticipatePin: 1,
});

// SECTION IMPACT
ScrollTrigger.create({
    animation: pinImpact,
    trigger: ".section__container_impact",
    //pin: true,
    start: "top center",
    // end: "bottom",
    // scrub: 0.5,
    //snap: 0.5,
    markers: false,
    toggleActions: "play none none reverse",
    anticipatePin: 1,
    //anticipatePin: 1,
    preventOverlaps: true,
});

// SECTION imports
ScrollTrigger.create({
    animation: pinImports,
    trigger: ".section__container_exports .imports",
    //pin: true,
    start: "top center",
    // end: "bottom",
    // scrub: 0.5,
    //snap: 0.5,
    markers: false,
    toggleActions: "play none none reverse",
    preventOverlaps: true,
    //anticipatePin: 1,
});

// SECTION exports
ScrollTrigger.create({
    animation: pinExports,
    trigger: ".section__container_exports .exports",
    //pin: true,
    start: "top center",
    // end: "bottom",
    // scrub: 0.5,
    //snap: 0.5,
    markers: false,
    toggleActions: "play none none reverse",
    //anticipatePin: 1,
    // preventOverlaps:true,
});

// SECTION FORYOU
ScrollTrigger.create({
    animation: pinForyou,
    trigger: ".section__container_foryou",
    //pin: true,
    start: "top center",
    // end: "bottom",
    // scrub: 0.5,
    //snap: 0.5,
    markers: false,
    toggleActions: "play none none reverse",
    
    preventOverlaps: true,
});



/**
 * showMenuScroll
 * * Check if the user is in mobile or desktop
 * * If desktop, show the menu on Scroll
 * ? Maybe refactor ?
 * TODO: outside home page there is no on scroll
 */
const showMenuScroll = () => {
  scrollHeightMax = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
  window.onscroll = (e) => {
    console.log('scrolled');
    //* MOSTRAR MENU FIXED */
    if (window.scrollY >= scrollHeightMax) {
      if (menuFlag) {
        menuA.classList.add('show');
        console.log('menutop showed');
        menuFlag = false;
      }
    } else if (!menuFlag) {
      menuA.classList.remove('show');
      console.log('menutop hidden');
      menuFlag = true;
    }
  };
};

const playHelloVideo = () =>{
    bodyDom.classList.add('overflow-hidden');
    const video = helloVideoPopup.querySelector('video');
    helloVideoPopup.classList.add('show');
    video.load();
    video.play();
    
};

const closeHelloVideo = ()=>{
    bodyDom.classList.remove("overflow-hidden");
    const video = helloVideoPopup.querySelector("video");
    helloVideoPopup.classList.remove("show");
    video.pause();
};

const init = () => {
    //animInit();
    showMenuScroll();
    helloVideo.addEventListener('click', playHelloVideo);
    helloVideoClose.addEventListener('click', closeHelloVideo);
}


window.onload = () =>{
    init();
};


